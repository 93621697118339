<template>
	<div id="app" ref="root">
		<WelcomeLayout v-if="this.inArray(this.$route.name, ['login', 'logout', 'register', 'restore', 'not-found', 'status'])" />
		<MainLayout v-else />
	</div>
  </template>
  
  <script>
  import WelcomeLayout from "@/layouts/WelcomeLayout";
  import MainLayout from '@/layouts/MainLayout'
  import * as app from './assets/scripts/app.js'
  import Session from "@/services/Session";
  import CommonService from "@/services/CommonService";
  import {copyText} from "vue3-clipboard";
  
  export default {
	  name: "app",
	  data () {
		  return {
			  config: {
				  dadata: {
					  token: '2f6595e671df25163a441a544bbed3683791c5d0',
				  },
				  yandexMap: {
					  apiKey: 'cc22eabe-8feb-4de8-8b82-8040f379f730',
					  lang: 'ru_RU',
					  coordorder: 'latlong',
					  enterprise: false,
					  coords: [59.89444, 30.26417],
					  version: '2.1'
				  },
				  get_events_timeout: 15, // in sec.
				  set_user_checkOnlineConn: 30, // in sec.
				  upload_accept: {
					  images: [
						  'image/jpg',
						  'image/jpeg',
						  'image/png',
						  'image/gif',
					  ],
					  files: [
						  'image/jpg',
						  'image/jpeg',
						  'image/png',
						  '.pdf',
						  '.txt',
						  '.doc',
						  '.docx',
						  '.xls',
						  '.xlsx',
					  ],
				  },
				  restricted_areas: [
					  "/",
					  "/search",
					  "/objects",
					  "/add-object",
					  "/edit-object",
					  "/adverts",
					  "/reservations",
					  "/clients",
					  "/add-client",
					  "/edit-client",
					  "/requisitions",
					  "/calendar",
					  "/employees",
					  "/partners",
					  "/docs"
				  ],
				  beta_testers_ids: [5427], // [4, 41, 93, 6635, 5427, 8136, 8137, 8138, 8139, 5571, 8140, 7741, 8435],
				  version: process.env.VERSION ?? '3.3.1',
				  version_is_beta: process.env.IS_BETA ?? false,
				  build: process.env.BUILD ?? null,
			  },
			  checkOnlineConn: navigator.checkOnlineConn ? true : false
		  }
	  },
	  components: {
		  WelcomeLayout,
		  MainLayout
	  },
	  methods: {
		  openLink(url, target) {
			  CommonService.log('info', 'openLink()', {url, target});
			  window.open(url, target);
		  },
		  copyToClipboard(text, element, messages) {
  
			  CommonService.log('info', 'copyToClipboard()', {text});
  
			  if (typeof element == "undefined")
				  element = document.querySelector('body');
  
			  copyText(text, element, (error, event) => {
				  if (error) {
					  console.error('Не удалось скопировать: ', error);
					  alert('Не удалось скопировать:(\r\n' + text);
  
					  if (typeof messages.error != "undefined")
						  alert(messages.error);
					  else
						  alert('Не удалось скопировать:(');
  
				  } else {
  
					  if (typeof messages.success != "undefined")
						  alert(messages.success);
					  else
						  alert('Успешно скопировано;)');
  
					  console.log(event);
				  }
			  })
		  },
		  inArray(needle, haystack) {
			  return CommonService.inArray(needle, haystack);
		  },
		  checkcheckOnlineConn() {
			  this.checkOnlineConn = navigator.checkOnlineConn ? true : false;
			  if (this.checkOnlineConn === true) {
				  console.log('You are checkOnlineConn! 😎');
			  } else {
				  console.log('Lost internet connection. ⛔');
			  }
			  return this.checkOnlineConn;
		  }
	  },
	  mounted() {
  
		  if (this.$store.getters.browserInfo == null) {
			  this.$store.commit('setBrowserInfo', {
				  'isAndroid': (CommonService.isAndroid()) ? true : false,
				  'isBlackBerry': (CommonService.isBlackBerry()) ? true : false,
				  'isiOS': (CommonService.isiOS()) ? true : false,
				  'isOpera': (CommonService.isOpera()) ? true : false,
				  'isSamsung': (CommonService.isSamsung()) ? true : false,
				  'isWindows': (CommonService.isWindows()) ? true : false,
				  'isMobile': (CommonService.isMobile()) ? true : false,
				  'isStandalone': (CommonService.isStandalone()) ? true : false,
				  'width': window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
				  'height': window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
				  'userAgent': navigator.userAgent,
			  });
		  }
  
		  if (this.$store.getters.config == null) {
			  this.$store.commit('setConfig', this.config);
		  }
  
		  window.addEventListener('checkOnlineConn',  this.checkcheckOnlineConn);
		  window.addEventListener('offline', this.checkcheckOnlineConn);
  
		  this.emitter.on('global.modalClose', (modal) => {
			  CommonService.log('debug', 'global.modalClose', modal);
			  var backdrops = document.querySelectorAll('body > .modal-backdrop.fade.show');
			  backdrops.forEach((elem, index) => {
				  if (index != 0) {
					  elem.parentNode.removeChild(elem);
				  }
				  setTimeout(() => {
					  if (document.querySelectorAll('.modal.fade.show').length == 0) {
						  elem.parentNode.removeChild(elem);
					  }
				  }, 600);
			  });
		  });
  
		  this.emitter.on('global.startGetObject', (data) => {
			  CommonService.log('debug', 'global.startGetObject', data);
		  });
  
		  this.emitter.on('global.endGetObject', (data) => {
			  CommonService.log('debug', 'global.endGetObject', data);
		  });
  
		  this.emitter.on('global.modalShown', (modal) => {
			  CommonService.log('debug', 'global.modalShown', modal);
		  });
  
		  this.emitter.on('openLink', (data) => {
			  CommonService.log('debug', 'openLink()::emitter', data);
			  this.openLink(data.url, data.target);
		  });
  
		  this.emitter.on('copyText', (data) => {
			  CommonService.log('debug', 'copyText()::emitter', data);
			  this.copyToClipboard(data.text, data.element, {success: data.success, error: data.error});
		  });
	  },
	  created() {
		  if ((!CommonService.isMobile() && !CommonService.isStandalone()) && !(process.env.NODE_ENV == "development")) {
			  this.$router.push('https://crm.intermarkproperty.ru/');
			  window.location.href = "https://crm.intermarkproperty.ru/";
		  } else {
			  window.addEventListener('beforeunload', function (event) {
				  //event.returnValue = 'Write something'
				  window.removeEventListener('checkOnlineConn');
				  window.removeEventListener('offline');
				  console.info('beforeUnLoad()');
  
			  }, false);
		  }
  
		  document.addEventListener('onMouseDown', (e) => {
			  e.preventDefault();
		  });
  
		  document.addEventListener('onKeyUp', (e) => {
			  if (e.keyCode === 13 || e.keyCode === 32) {
				  e.preventDefault();
			  }
		  });
	  },
	  /*updated() {
		  console.log(document.querySelector("body"));
	  },*/
	  watch: {
		  /*body_class: function (val) {
			  console.log(val);
		  },*/
		  $route (to, from) {
  
			  CommonService.log('log', '$route', {to, from});
			  this.$store.commit("setPageTransition", "back");
  
			  CommonService.clearAllModals();
			  CommonService.clearAllPopovers();
  
			  if (this.inArray(to.path, this.config.restricted_areas)) {
				  if (to.path !== '/error-402' && this.$store.getters.userInfo.is_restricted_access) {
					  Session.clear(true);
					  Session.clear();
					  this.$store.commit('setSessionId', null);
					  this.$store.commit('setUserInfo', {});
					  this.$router.push('/error-402');
				  }
			  }
		  },
		  checkOnlineConn: function (val) {
			  let header = document.body.querySelector('header.header');
			  if (header) {
				  if (!val) {
					  document.body.classList.add("on-failure");
					  document.body.classList.add("no-wifi-icon");
					  header.setAttribute('data-error', 'Отсутствует подключение к сети.');
				  } else {
					  document.body.classList.remove("on-failure");
					  document.body.classList.remove("no-wifi-icon");
					  header.removeAttribute('data-error');
				  }
			  }
		  },
	  },
	  computed: {
		  /*body_class() {
			  return document.querySelector('body').classList;
		  },*/
	  },
	  beforeUnmount() {
		  window.removeEventListener('checkOnlineConn');
		  window.removeEventListener('offline');
	  },
	  beforeRouteLeave(to, from, next) {
		  // incase if you want to access `this`
		  // const self = this as any;
		  console.info('app::beforeRouteLeave()', to);
		  next();
	  },
	  beforeRouteUpdate (to, from, next) {
		  // called when the route that renders this component has changed,
		  // but this component is reused in the new route.
		  // For example, for a route with dynamic params `/foo/:id`, when we
		  // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
		  // will be reused, and this hook will be called when that happens.
		  // has access to `this` component instance.
  
		  //const id = to.params.id
		  console.info('app::beforeRouteUpdate()', to);
		  next();
	  },
  };
  </script>
  
  <style lang="scss">
  @import "@/assets/styles/app.scss";
  </style>
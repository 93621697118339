<template>
	<transition name="fade">
		<div v-if="!this.open" class="crm d-flex flex-column" style="height: 100%; min-height: 100vh;">
			<div class="welcome d-flex flex-column my-auto justify-content-center align-items-center">
				<div class="col-12 col-sm-9 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mx-auto">
					<img :src="require('@/assets/svg/joywork-logo-anim.svg')"
						 alt="JoyWork"
						 class="d-flex px-5 py-3 mw-80 mx-auto mt-auto mb-2" />
				</div>
				<transition name="fade2">
					<div v-if="!this.open2" class="container-fluid">
						<router-view />
					</div>
				</transition>
			</div>
			<transition name="fade3">
				<div v-if="!this.open3">
					<Footer />
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
import { ref } from 'vue'
import Footer from '../components/common/Footer'
import CommonService from "@/services/CommonService";

export default {
    name: "WelcomeLayout",
	setup() {
		const open = ref(true);
		const open2 = ref(true);
		const open3 = ref(true);
		return {
			open,
			open2,
			open3,
		}
	},
	mounted() {
		CommonService.debounce(() => {
			this.open = !this.open;
			CommonService.debounce(() => {
				this.open2 = !this.open2;
				CommonService.debounce(() => {
					this.open3 = !this.open3;
				}, 300);
			}, 300);
		}, 300);
	},
	components: {
        Footer
    }
};
</script>

<style lang="scss">
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.6s ease 0.6s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}


	.fade2-enter-active,
	.fade2-leave-active {
		transition: opacity 0.6s ease 0.6s;
	}
	.fade2-enter-from,
	.fade2-leave-to {
		opacity: 0;
	}


	.fade3-enter-active,
	.fade3-leave-active {
		transition: opacity 0.6s ease 0.6s;
	}
	.fade3-enter-from,
	.fade3-leave-to {
		opacity: 0;
	}
</style>

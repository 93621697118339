<template>
	<div class="offcanvas offcanvas-end" tabindex="-1" id="userSettings" aria-labelledby="userSettingsLabel">
		<div class="offcanvas-header p-0 bg-light bo">
			<span class="fw-semi fs-2 text-dark font-semi ps-4">
				Профиль
			</span>
			<button type="button" class="burger crm__btn bg-white border-0 p-0 ms-auto"
					data-bs-dismiss="offcanvas"
					aria-label="Close">
				<img :src="require('@/assets/icons/close-icon.svg')" alt="Закрыть" width="24" height="24">
			</button>
		</div>
		<div class="offcanvas-body d-flex flex-column bg-white pt-3 ps-4">

			<dl>
				<dt>Тариф: </dt>
				<dd v-if="this.user.services.tarif.expired == false && this.user.services.tarif.days > 0"
					v-text="this.declOfNum(this.user.services.tarif.days, ['день', 'дня', 'дней'], true)" />
				<dd v-else
					v-text="'не оплачен'" />
				<dt>ЕГРН: </dt>
				<dd v-if="this.user.services.egrn_count"
					v-text="this.declOfNum(this.user.services.egrn_count, ['выписка', 'выписок', 'выписок'], true)" />
				<dd v-else
					v-text="'0 выписок'" />
				<dt>Zipal: </dt>
				<dd v-if="this.user.services.balance"
					v-text="this.declOfNum(this.user.services.balance, ['рубль', 'рубля', 'рублей'], true, true)" />
				<dd v-else
					v-text="'0 выписок'" />
				<dt>Очистка фото: </dt>
				<dd v-if="this.user.services.antiznak_count"
					v-text="this.user.services.antiznak_count + ' фото'" />
				<dd v-else
					v-text="'0 фото'" />
			</dl>

			<div v-if="this.user.services.use_advert_budget">
				<h3 v-if="!this.isEmpty(this.user.services.advert_budget.label)" class="pb-3 border-bottom">
					<img :src="require('@/assets/svg/coins.svg')" width="24" height="24" />
					{{ this.user.services.advert_budget.label }}
				</h3>
				<h3 v-else class="pb-3 border-bottom">
					<img :src="require('@/assets/svg/coins.svg')" width="24" height="24" />
					Рекламный бюджет
				</h3>
				
				<dl class="dl-horizontal">

					<dt>Avito: </dt>
						<dd v-if="this.user.services.advert_budget.avito.is_unlimited">не ограничено ({{ this.formatPrice(this.user.services.advert_budget.avito.amount) }} руб. из ∞ руб.)</dd>
						<dd v-else :class="{'is_hight': (this.user.services.advert_budget.avito.available), 'is_lower': (!this.user.services.advert_budget.avito.available)}">{{ this.formatPrice(this.user.services.advert_budget.avito.available) }} руб. ({{ this.formatPrice(this.user.services.advert_budget.avito.amount) }} руб. из {{ this.formatPrice(this.user.services.advert_budget.avito.total) }} руб.)</dd>

					<dt>ЦИАН: </dt>
						<dd v-if="this.user.services.advert_budget.cian.is_unlimited">не ограничено ({{ this.formatPrice(this.user.services.advert_budget.cian.amount) }} руб. из ∞ руб.)</dd>
						<dd v-else :class="{'is_hight': (this.user.services.advert_budget.cian.available), 'is_lower': (!this.user.services.advert_budget.cian.available)}">{{ this.formatPrice(this.user.services.advert_budget.cian.available) }} руб. ({{ this.formatPrice(this.user.services.advert_budget.cian.amount) }} руб. из {{ this.formatPrice(this.user.services.advert_budget.cian.total) }} руб.)</dd>

					<dt>ДомКлик: </dt>
						<dd v-if="this.user.services.advert_budget.domclick.is_unlimited">не ограничено ({{ this.formatPrice(this.user.services.advert_budget.domclick.amount) }} руб. из ∞ руб.)</dd>
						<dd v-else :class="{'is_hight': (this.user.services.advert_budget.domclick.available), 'is_lower': (!this.user.services.advert_budget.domclick.available)}">{{ this.formatPrice(this.user.services.advert_budget.domclick.available) }} руб. ({{ this.formatPrice(this.user.services.advert_budget.domclick.amount) }} руб. из {{ this.formatPrice(this.user.services.advert_budget.domclick.total) }} руб.)</dd>

					<dt>JCat: </dt>
						<dd v-if="this.user.services.advert_budget.jcat.is_unlimited">не ограничено ({{ this.formatPrice(this.user.services.advert_budget.jcat.amount) }} руб. из ∞ руб.)</dd>
						<dd v-else :class="{'is_hight': (this.user.services.advert_budget.jcat.available), 'is_lower': (!this.user.services.advert_budget.jcat.available)}">{{ this.formatPrice(this.user.services.advert_budget.jcat.available) }} руб. ({{ this.formatPrice(this.user.services.advert_budget.jcat.amount) }} руб. из {{ this.formatPrice(this.user.services.advert_budget.jcat.total) }} руб.)</dd>

					<dt>Я.Недвижимость: </dt>
						<dd v-if="this.user.services.advert_budget.yandex.is_unlimited">не ограничено ({{ this.formatPrice(this.user.services.advert_budget.yandex.amount) }} руб. из ∞ руб.)</dd>
						<dd v-else :class="{'is_hight': (this.user.services.advert_budget.yandex.available), 'is_lower': (!this.user.services.advert_budget.yandex.available)}">{{ this.formatPrice(this.user.services.advert_budget.yandex.available) }} руб. ({{ this.formatPrice(this.user.services.advert_budget.yandex.amount) }} руб. из {{ this.formatPrice(this.user.services.advert_budget.yandex.total) }} руб.)</dd>

				</dl>

				<dl class="dl-horizontal border-top mt-3 pt-2">
					<dt>Всего: </dt>
					<dd v-if="this.user.services.advert_budget.total > 0"
						v-text="this.declOfNum(this.user.services.advert_budget.total, ['рубль', 'рубля', 'рублей'], true, true)" />
					<dd v-else
						v-text="'0 рублей'" />
					<dt>Списано: </dt>
					<dd v-if="this.user.services.advert_budget.amount > 0"
						v-text="this.declOfNum(this.user.services.advert_budget.amount, ['рубль', 'рубля', 'рублей'], true, true)" />
					<dd v-else
						v-text="'0 рублей'" />
					<dt>Доступно: </dt>
					<dd v-if="this.user.services.advert_budget.balance > 0"
						class="is_hight"
						v-text="this.declOfNum(this.user.services.advert_budget.balance, ['рубль', 'рубля', 'рублей'], true, true)" />
					<dd v-else
						class="is_lower"
						v-text="'0 рублей'" />
				</dl>
			</div>


<!--			<div class="row">
				<VariablesList
					:variablesList="this.$store.getters.userInfo" />
			</div>-->
		</div>
	</div>
</template>

<script>
    import VariablesList from "@/components/common/VariablesList";
	import CommonService from "@/services/CommonService";
	export default {
        name: "UserProfile",
		props: {
			user: {
				type: Object,
				default: null
			}
		},
		components: {
			//VariablesList
		},
		data() {
            return {
            }
        },
		methods: {
			declOfNum(number, titles, append, is_price) {
				return CommonService.declOfNum(number, titles, append, is_price);
			},
			formatPrice(value, currency, sign) {
				return CommonService.formatPrice(value, currency, sign);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
        },
		watch: {
			'user': function(val, oldVal) {
				console.info(val.services.advert_budget);
			},
		},
        computed: {
			
        }
    };
</script>
